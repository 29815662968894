import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "介護のほんねリニューアルの話",
  "date": "2021-04-06T09:00:10.000Z",
  "slug": "entry/2021/04/06/180010",
  "tags": ["medley"],
  "hero": "./2021_04_06.png",
  "heroAlt": "ほんねリニューアル"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`はじめまして。メドレーでデザイナーをしているおおのです。わたしはメドレーには昨年（2020 年）の 6 月に入社し、現在老人ホーム・介護施設の検索サイト「`}<a parentName="p" {...{
        "href": "https://www.kaigonohonne.com/"
      }}>{`介護のほんね`}</a>{`」を担当しています。`}</p>
    <p>{`介護のほんねは昨年リニューアルを行いました。今回は、そのリニューアルプロジェクトの中で自分が取り組んだこと（主にサイトトップのリニューアルについて）についてお話しようと思います。`}</p>
    <h1>{`目次`}</h1>
    <ol>
      <li parentName="ol">{`介護のほんねとは`}</li>
      <li parentName="ol">{`リニューアルの背景`}</li>
      <li parentName="ol">{`プロジェクトについて`}</li>
      <li parentName="ol">{`プロジェクトとの関わりについて`}</li>
      <li parentName="ol">{`サイトトップの制作`}</li>
      <li parentName="ol">{`プロジェクトを終えて`}</li>
    </ol>
    <h1>{`介護のほんねとは`}</h1>
    <p>{`介護のほんねは、納得できる老人ホーム・介護施設探しができる検索サイトです。介護のほんねには、全国にある多くの施設が掲載されています。予算やエリアなどお好みの条件で施設を検索したり、気になった施設へ見学予約や資料請求などお問い合わせができます。また社内の入居相談員による施設に関する資料送付や条件にあった施設紹介、施設見学の日程調整などのサポートにも対応しています。`}</p>
    <h1>{`リニューアルの背景`}</h1>
    <p>{`介護のほんねは 2014 年にローンチされました。しかし、長い間の運用の中で古いデザインと新しいデザインが入り混じっている部分があったり、SEO の観点での強化が必要だったり、今後の成長に向けて手直しする部分が積もり始めていました。また、2019 年に「医療につよい老人ホーム検索サイト」にコンセプトを変更しましたが、より多くの方にご利用いただくためにも、医療につよいというコンセプトからさらに一歩進み、様々な状況のお客様に向き合い、お客様が介護に対して前向きに、そして後悔のない選択ができるよう寄り添うことのできるサービスにしていきたいという思いから今回のリニューアルが始まりました。`}</p>
    <h1>{`プロジェクトについて`}</h1>
    <p>{`リニューアルプロジェクトは昨年 5 月頃から始まり、外部のデザイン制作会社と連携して進められました。制作会社の方には、デザイン業務の支援をお願いしつつ、週 1〜2 回の定例で進捗報告や業務内容の確認を行っていました。`}</p>
    <h1>{`プロジェクトとの関わりについて`}</h1>
    <p>{`自分が入社したのが昨年 6 月後半だったので、デザインや開発も部分的に進んでいる状況でした。介護領域の勉強や、担当サービス、競合調査、リニューアルプロジェクトや介護のほんねのこれまでの歩みについてなどのキャッチアップと並行してリニューアルのデザインのことも考えていました。`}</p>
    <p>{`そこで次のようなことを意識して動きました。`}</p>
    <h2>{`プロジェクトに対して`}</h2>
    <ul>
      <li parentName="ul">{`社内のメンバーといつでもコミュニケーションがとれることを活かし、外注先とも相談して自分自身も手を動かしながらデザインをブラッシュアップすること`}</li>
    </ul>
    <h2>{`社内に対して`}</h2>
    <ul>
      <li parentName="ul">{`サービスに対するメンバーの思いを確認しつつ、これまでのサービスの歩みを尊重して動くこと`}</li>
      <li parentName="ul">{`社内のデザイナーの先輩など頼れる人には頼ること`}</li>
    </ul>
    <h1>{`サイトトップの制作`}</h1>
    <p>{`プロジェクトにジョイン後、主にサービスの顔であるサイトトップについて、アイデア出しやデザインをしました。`}</p>
    <p>{`サイトトップにどのようなコンテンツを掲載するのか、また、お客様が介護に対して前向きに、そして後悔のない選択ができるよう寄り添いたいというサービスの思いをどのように表現すればよいか考えていきました。`}</p>
    <p>{`掲載するコンテンツに関しては、サイトトップを誰に向けて作るのかという部分を介護施設探しが初めての人に設定し、このサイトでは施設が探せることを伝えてサービスのコア機能を全面に出しつつ、介護のほんねでの施設探しの魅力ポイントや、介護や施設探しに関するコラムや Q&A などお役立ち情報も掲載するようにしました。`}</p>
    <b>▼ リニューアル前のサイトトップ</b>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20210401/20210401103421.png",
      "alt": "20210401103421.png"
    }}></img>
    <p>{`また、当時のサイトトップは上に貼ったキャプチャの通りで、落ち着いた青を基調としたクリーンで誠実そうな印象がありました。これまで築き上げてきたプロダクトのイメージや印象は、リニューアル後も受け継いで残していきたいなと思いました。`}</p>
    <p>{`それをふまえた上で、サイトトップの新しいキャッチコピーやキービジュアルをどういうものにするのかプロジェクトのメンバーとアイデアを出しながら考えました。しかし、「どのアイデアも間違ってないけどもっといいのがありそうだな」という気持ちが拭えず、なかなか決まりませんでした。`}</p>
    <p>{`そこで、改めて原点に帰って情報を整理するために次のことに取り組みました。`}</p>
    <ul>
      <li parentName="ul">{`ユーザーを知る`}</li>
      <li parentName="ul">{`介護のほんねが提供する価値を整理する`}</li>
      <li parentName="ul">{`信頼できる情報から納得できる介護サービスと出会えることをキャッチコピーに落とす`}</li>
      <li parentName="ul">{`介護のほんねの世界観を視覚的に伝えられるようなメインビジュアルの選定`}</li>
    </ul>
    <h2>{`ユーザーを知る`}</h2>
    <p>{`まずはじめに介護のほんねのユーザーデータを 1 件 1 件見ていきました。そこにはお客様の情報や施設を探している理由など様々な情報がまとまっています。それらのデータを見ていくと、おおよそ 4 つのパターンにわけられることがわかりました。`}</p>
    <p>{`① 退院後の施設を探したい`}<br />{`
転倒など何かしらの理由で入院している家族の退院期限が迫っており、退院後に在宅での介護ではなく施設にいれる必要があるケース`}</p>
    <p>{`② 施設を移動しないといけなくなった`}<br />{`
介護度があがったことで施設の受け入れ可能範囲から外れた場合や、施設の中でトラブルがあるなどの事情により施設を移る必要があるケース`}</p>
    <p>{`③ 今後に備えて早めに動きたい`}<br />{`
今すぐ介護施設に入れる必要があるわけではないものの、親が高齢でひとり暮らしをしていて不安なためまだ自分で動けるうちに施設にいれておきたいケース`}</p>
    <p>{`④ 在宅介護では限界がきてしまった`}<br />{`
自分自身も高齢になってきたため、仕事や家事に加えて介護の両立が難しくなってきた方が施設を探しているケース`}</p>
    <p>{`このようなお客様のデータを見ていると、事情が事情だけになるべく急いで施設を探しているものの、離れても家族が幸せに暮らせるように慎重に施設を探したい、という思いが伝わってきました。`}</p>
    <b>▼ ユーザーの大まかなパターン</b>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20210401/20210401115623.png",
      "alt": "20210401115623.png"
    }}></img>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20210401/20210401115632.png",
      "alt": "20210401115632.png"
    }}></img>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20210401/20210401115641.png",
      "alt": "20210401115641.png"
    }}></img>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20210401/20210401115649.png",
      "alt": "20210401115649.png"
    }}></img>
    <h2>{`介護のほんねが提供する価値を整理する`}</h2>
    <p>{`次に、ユーザーの方に対して介護のほんねができることはどういうことかを整理しました。`}</p>
    <p>{`介護のほんねは「老人ホーム・介護施設の検索サイト」ということからもわかるように、施設を探すことができ、プロの入居相談員が施設探しから実際の入居までサポートするサービスです。また介護のほんねとしては、お客様が介護に対して前向きに、そして後悔のない施設探しができるよう寄り添いたいという思いがあります。そこで、介護のほんねの価値を機能的なものと情緒的なものにわけて整理してみました。`}</p>
    <p>{`機能的価値＝すぐに納得できる施設が見つかること`}<br />{`
情緒的価値＝家族のために、いろんな思いをもって施設探しをしているユーザーに寄り添うこと`}</p>
    <p>{`ユーザーデータから見えてきた「事情が事情だけになるべく急いで施設を探しているものの離れても幸せに暮らせるように慎重に施設を探したい」、そのようなユーザーに介護のほんねは寄り添って、後悔することなく納得できる施設がすぐに見つかるようにサポートしていくことを伝えたいなと思いました。`}</p>
    <h2>{`納得できる介護サービスと出会えることをキャッチコピーに落とす`}</h2>
    <p>{`ユーザーやサービスの提供価値について整理をしたところで、新しいコンセプトをサイトトップのキャッチコピーにどう落とし込むのかを考えました。`}</p>
    <p>{`そもそもサイトトップのキャッチコピーですので、前提として「サービス概要、コンセプトが端的に伝わること」は大事にしたいと思いました。サービス概要は、繰り返しになりますが老人ホーム・介護施設の検索サイトです。そして端的にサービス価値を伝えるためにも、先程述べたサービスの機能的価値である「すぐに納得できる施設がみつかること」をキャッチコピーに盛り込もうと考えました。`}</p>
    <p>{`色々アイデアを出した結果、「老人ホームが見つかる」というサービスのコア機能に加え、介護のほんねならではの「すぐに」見つかるということや、後悔のない納得いく施設選びができるというエッセンスを入れて、最終的に「納得できる老人ホームがすぐ見つかる」というコピーになりました。`}</p>
    <h2>{`介護のほんねの世界観を視覚的に伝えられるようなメインビジュアルの選定`}</h2>
    <p>{`キャッチコピーはサービスの機能やできることをわかりやすく伝えるものにしたため、キービジュアルは先程述べたサービスの情緒的価値のエッセンスをいれたいと思いました。それを踏まえ、いろんな思いを持った相談者の方に寄り添い、ご家族が施設に入居されてから始まる新しい生活を前向きに捉えられるようなビジュアルにしようと思いました。`}</p>
    <p>{`キービジュアルの選定にあたり、チーム内で意見を集めながら、色々アイデアが出ました。入居後のイメージやサービス概要が伝わりやすい老人ホームの屋内風景の写真、入居後の楽しい生活が期待できそうな施設のスタッフと入居者の笑顔の写真や、サービスの寄り添うスタンスを抽象的に伝える手を握り合うような写真など、たくさんの写真をあてはめて検討を繰り返しました。`}</p>
    <b>▼ イメージ選定</b>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20210401/20210401103430.png",
      "alt": "20210401103430.png"
    }}></img>
    <ul>
      <li parentName="ul">{`車椅子が写りこんでいると、自立度が高い状態で施設を探している方（※介護の必要がない元気な方向けの施設もあり、元気なうちから施設に入られる方もいらっしゃいます）にとって自分が使っていいサービスではないのかとマイナスなイメージにならないか？`}</li>
      <li parentName="ul">{`施設スタッフと入居者が笑顔で写っている人が写ったモデル風の綺麗な写真だと素材感が出すぎて嘘っぽくならないか？`}</li>
      <li parentName="ul">{`手を包みこむなどモチーフが抽象的すぎるとかえって何も伝わらないのではないか？`}</li>
    </ul>
    <p>{`写真を選ぶ中でチーム内でも相談しながら、小さな違和感をひとつずつつぶしていきました。そして、最終的に下のようなキービジュアルになりました。`}</p>
    <b>▼ リニューアル前とリニューアル後</b>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20210401/20210401115327.png",
      "alt": "20210401115327.png"
    }}></img>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20210401/20210401115319.png",
      "alt": "20210401115319.png"
    }}></img>
    <p>{`これからの新しい生活がポジティブなものに捉えられるような、スタッフと笑顔で生活する入居者が写っており、背景に程よく雑多感が残る素材感を抑えた写真を選びました。写真に写っているのは入居者と入居者に寄り添うスタッフですが、介護のほんねも同じように、施設探しをしている方に寄り添う姿勢がこの写真から間接的に伝われば嬉しいなと思っています。`}</p>
    <h1>{`プロジェクトを終えて`}</h1>
    <p>{`プロジェクトは一段落しましたが、スタートラインにたったところなので、まだまだ追加したい機能や磨き込みたい部分も山積みだなと感じています。`}</p>
    <p>{`今回、自分のデザインに納得感をだすためにサービスや介護の知識、チームのメンバーが考えていることへの理解を深めながら並行してリニューアルのデザインを手掛けたことは、とてもやりがいのあるものでした。`}</p>
    <p>{`また、リニューアルをきっかけに改めてサービスの価値や目指したい世界を整理できたのはとても良かったです。これからも介護のほんねの目指したい姿を見据えながら、より多くの方につかってもらえるようなサービスにしていきたいと思っています。`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.medley.jp/jobs/designer-new.html"
      }}>{`https://www.medley.jp/jobs/designer-new.html`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      